@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Black'), local('Roboto-Black'), url('../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Bold'), local('Roboto-Bold'), url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Italic'), local('Roboto-Italic'), url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-BoldItalic'), local('Roboto-BoldItalic'), url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Regular'), local('Roboto-Regular'), url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Light'), local('Roboto-Light'), url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto-Medium'), local('Roboto-Medium'), url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins-Black'), local('Poppins-Black'), url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
	font-weight: bolder;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins-Bold'), local('Poppins-Bold'), url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins-SemiBold'), local('Poppins-SemiBold'), url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins-Italic'), local('Poppins-Italic'), url('../assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins-BoldItalic'), local('Poppins-BoldItalic'), url('../assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins-Regular'), local('Poppins-Regular'), url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
	font-weight: normal;
}
