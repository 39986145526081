ion-title {
	font-size: 18px;
	text-align: center;
}

ion-button {
	z-index: 100;
}

ion-title {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}
.text-normal {
	font-size: 14px;
	font-weight: normal;
}

.text-title {
	font-size: 22px;
	font-weight: bold;
}

.text-subtitle {
	font-weight: bold;
	font-size: 14px;
}

.header-md::after {
	background-image: none;
}

.button-solid {
	box-shadow: none;
	--box-shadow: none;
	--border-radius: 2;
	--letter-spacing: 0;
	letter-spacing: 0;
	text-transform: none;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 29px;
}

ion-footer {
	background: white;
	// color: white;
	// height: 90px;
	// border-radius: 20px 20px 0px 0px;
	// margin-top: -16px;

	// .button-solid {
	// 	font-weight: 500;
	// 	font-size: 20px;
	// 	line-height: 40px;
	// 	margin: 0;
	// 	margin-top: auto;
	// }
}

.ion-page {
	background: var(--ion-color-grey);
}

//Form
.form {
	padding-left: 16px;
	padding-right: 16px;
	text-align: center;

	ion-label {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 8px;
		display: block;
	}

	ion-item {
		background: #ffffff;
		border: 2px solid #2d3330;
		box-sizing: border-box;
		border-radius: 60px;
		margin-bottom: 16px;
		--border-width: 0;
		--highlight-height: 0px;
		--min-height: 40px;
	}
}

.button-solid,
ion-button {
	--border-radius: 43px;
	--min-height: 40px;
}

//List
.list-aauav {
	padding-top: 0;
	ion-item {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		border-style: solid;
		border: 0.5px solid #dbdbdb;
		border-width: 1px 1px 0px 1px;
		--border-width: 0;
		--inner-border-width: 0;

		color: #1b1b1b;
		font-size: 16px;
		font-weight: 500;
		padding-left: 5px;
	}
}

.alert-radio-label.sc-ion-alert-md {
	white-space: normal;
}

form {
	max-width: 500px;
	margin: auto;
}
